import { Button, Divider, Form, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ArticleContent from './ArticleContent';
import ArticleInfo from './ArticleInfo';
import { getArticle, putArticle } from '../../services/articleService';
import AppResult from '../common/AppResult';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { postImage } from '../../services/imageService';

export default function UpdateArticle() {
  const location = useLocation();
  const [form] = Form.useForm();
  const [responseResult, setResponseResult] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [contentList, setContentList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getArticle(location.state.id).then((response) => {
      response.articleContents = response.articleContents.sort(
        (a, b) => a.position - b.position
      );
      setData(response);
      setContentList(response.articleContents);
    });
  }, [location]);

  const onFinish = async (values) => {
    setLoading(true);
    values.id = data.id;
    values.newImages = [];
    if (typeof values.coverImageData == 'object') {
      values.coverImageData = await postImage(
        'article',
        values.coverImageData[0].originFileObj
      );
      values.newImages.push(values.coverImageData);
    }

    for (let i = 0; i < values.articleContents.length; ++i) {
      values.articleContents[i].position = i;
      if (
        values.articleContents[i].type === 'image' &&
        typeof values.articleContents[i].item == 'object'
      ) {
        values.articleContents[i].item = await postImage(
          'article-content',
          values.articleContents[i].item[0].originFileObj
        );
        values.newImages.push(values.articleContents[i].item);
      }
      delete values.articleContents[i].id;
    }
    const id = values.id;
    delete values.id;

    putArticle(id, values).then((response) => {
      setLoading(false);
      if (response?.id) {
        setResponseResult({
          status: 'success',
          title: 'Отлично!',
          subtitle: `Статья «${values.title}» изменена.`,
        });
      } else {
        setResponseResult({
          status: 'error',
          title: 'Ошибка',
          subtitle: response,
          additionalButtonText: 'Назад',
          additionalLogic: () => setResponseResult(null),
        });
      }
    });
  };

  if (!data) return <Spin />;

  return (
    <>
      {!responseResult && (
        <Spin spinning={loading} delay={500}>
          <h1 style={{ textAlign: 'left', marginTop: '0px' }}>
            Редактирование
          </h1>
          <Form form={form} onFinish={onFinish} initialValues={data}>
            <ArticleInfo coverImage={data.coverImageData} />

            {contentList.length > 0 && (
              <Divider style={{ marginTop: '0px' }}>Содержание</Divider>
            )}

            <Form.List name='articleContents'>
              {(fields, { add, remove, move }) => (
                <>
                  {fields.map(({ key, name }) => (
                    <ArticleContent
                      key={key}
                      name={name}
                      form={form}
                      remove={(n) => {
                        remove(n);
                        setContentList(
                          form.getFieldValue([['articleContents']])
                        );
                      }}
                      move={move}
                      countContents={contentList.length}
                      image={
                        name < data.articleContents.length &&
                        data.articleContents[name].type === 'image'
                          ? data.articleContents[name].item
                          : null
                      }
                    />
                  ))}
                  <Form.Item style={{ marginRight: '3rem' }}>
                    <Button
                      type='dashed'
                      onClick={() => {
                        add();
                        setContentList(
                          form.getFieldValue([['articleContents']])
                        );
                      }}
                      block
                      icon={<PlusOutlined />}
                    >
                      Добавить поле
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>

            <Form.Item style={{ marginRight: '3rem' }}>
              <Button
                onClick={() => {
                  form.resetFields();
                  navigate('/article');
                }}
                style={{ marginRight: '2rem', width: '95px' }}
              >
                Назад
              </Button>
              <Button
                htmlType='submit'
                type='primary'
                disabled={contentList.length === 0}
              >
                Изменить
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      )}
      {responseResult && (
        <AppResult data={responseResult} pathSection={'/article'} />
      )}
    </>
  );
}
