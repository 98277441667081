import { ConfigProvider } from 'antd';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';

const themeApp = {
  token: {
    colorPrimary: '#C56BFA',
    colorTextBase: '#2E2C3C',
    colorIcon: '#2E2C3C',
    colorTextPlaceholder: '#918fa3',
    colorError: '#F7698C',
    borderRadius: '10px',
    borderRadiusSM: '4px',
  },
};

const App = () => {
  return (
    <BrowserRouter>
      <ConfigProvider theme={themeApp}>
        <Routes>
          <Route path='/' element={<Navigate to='/home' replace />} />
          <Route path='/login' element={<Login />} />
          <Route path='*' element={<Home />}></Route>
        </Routes>
      </ConfigProvider>
    </BrowserRouter>
  );
};
export default App;
