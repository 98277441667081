import { Card, Flex, Form, Divider, Input, TimePicker } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

export default function PracticeStep({ name, remove }) {
  return (
    <Flex style={{ justifyContent: 'space-between' }}>
      <Card
        style={{
          width: '100%',
          marginRight: '1rem',
          marginBottom: '1rem',
        }}
      >
        <Divider orientation='left' style={{ marginTop: '-0.5rem' }}>
          {`Шаг ${name + 1}`}
        </Divider>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Form.Item
            name={[name, 'time']}
            style={{ width: '115px', marginRight: '24px', marginBottom: 0 }}
            rules={[{ required: true, message: '' }]}
          >
            <TimePicker
              placeholder='Время'
              format={'mm:ss'}
              showNow={false}
              needConfirm={false}
            />
          </Form.Item>
          <Form.Item
            name={[name, 'description']}
            rules={[{ required: true, message: '' }]}
            style={{
              width: '100%',
              marginRight: '0.5rem',
              textAlign: 'left',
              marginBottom: 0,
            }}
          >
            <Input.TextArea autoSize placeholder='Описание' />
          </Form.Item>
        </div>
      </Card>
      <CloseOutlined onClick={() => remove(name)} style={{ width: '1rem' }} />
    </Flex>
  );
}
