import { Flex, Form, Input, InputNumber } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

export default function TestOption({ nameOption, removeOption }) {
  return (
    <Flex style={{ width: '30%', marginLeft: 0 }} gap={'5%'}>
      <Form.Item
        name={[nameOption, 'weight']}
        rules={[{ required: true, message: '' }]}
        style={{ width: '30%', marginLeft: 0 }}
      >
        <InputNumber placeholder='Вес' />
      </Form.Item>
      <Form.Item
        name={[nameOption, 'text']}
        rules={[{ required: true, message: '' }]}
        style={{ width: '80%' }}
      >
        <Input placeholder='Текст' />
      </Form.Item>
      <Form.Item>
        <CloseOutlined
          onClick={() => {
            removeOption(nameOption);
          }}
        />
      </Form.Item>
    </Flex>
  );
}
