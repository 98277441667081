import {
  FundOutlined,
  ReadOutlined,
  TrophyOutlined,
  OrderedListOutlined,
  PoweroffOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import mainLogo from '../../data/assets/logo.svg';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../services/userService';

const sliderStyle = {
  background: '#21232C',
  position: 'fixed',
  zIndex: 2,
  height: '100vh',
};

export default function AppSider({ collapsed }) {
  const navigate = useNavigate();

  return (
    <Layout.Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      width='15%'
      style={sliderStyle}
    >
      <div>
        <img
          src={mainLogo}
          alt='StressTouch'
          style={{
            width: collapsed ? 40 : 100,
            margin: collapsed ? '1rem' : '2.5rem',
            marginLeft: collapsed ? '1.2rem' : '30px',
          }}
        />
      </div>
      <Menu
        onClick={({ key }) => {
          if (key === 'logout') {
            sessionStorage.clear();
            localStorage.removeItem('tokenRefresh');
            localStorage.removeItem('expiresIn');
            logout({ tokenAccess: localStorage.getItem('tokenAccess') });
            localStorage.removeItem('tokenAccess');
            navigate('/login');
          } else {
            navigate(key);
          }
        }}
        theme='dark'
        mode='inline'
        defaultSelectedKeys={[window.location.pathname.split('/')[1]]}
        style={{ width: '100%', background: '#21232C' }}
        items={[
          {
            key: 'article',
            icon: <ReadOutlined />,
            label: 'Статьи',
          },
          {
            key: 'exercise',
            icon: <FundOutlined />,
            label: 'Упражнения',
          },
          {
            key: 'practice',
            icon: <TrophyOutlined />,
            label: 'Практики',
          },
          {
            key: 'test',
            icon: <OrderedListOutlined />,
            label: 'Тесты',
          },
          {
            key: 'logout',
            icon: <PoweroffOutlined />,
            label: 'Выход',
            danger: true,
          },
        ]}
      />
    </Layout.Sider>
  );
}
