import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Form, Input, Spin, Typography } from 'antd';
import mainLogo from '../data/assets/logo.svg';
import { login } from '../services/userService';
import { Navigate, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { addSeconds } from 'date-fns';

export default function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [validateMess, setValidateMess] = useState();

  const onFinish = (values) => {
    setLoading(true);
    delete values.remember;
    values.cms = true;
    login(values).then((response) => {
      setLoading(false);
      if (response.role === 'admin') {
        setValidateMess(setValidateMess);
        localStorage.setItem('tokenAccess', response.tokensData.tokenAccess);
        localStorage.setItem('tokenRefresh', response.tokensData.tokenRefresh);
        localStorage.setItem(
          'expiresIn',
          addSeconds(new Date(), response.tokensData.expiresIn - 30)
        );
        navigate('/home');
      } else {
        if (response.statusCode === 400) {
          setValidateMess('Неправильный логин или пароль');
        } else {
          setValidateMess(response.message);
        }
      }
    });
  };
  if (localStorage.getItem('tokenRefresh'))
    return <Navigate to='/home' replace />;
  return (
    <div
      style={{
        height: '100vh',
        display: 'grid',
        placeItems: 'center',
        background: '#2E2C3C',
      }}
    >
      <Spin spinning={loading} delay={500}>
        <Card
          style={{
            width: 350,
            marginBottom: 50,
          }}
        >
          <Form
            initialValues={{
              remember: true,
            }}
            name='login'
            onFinish={onFinish}
          >
            <Form.Item style={{ textAlign: 'center' }}>
              <img
                src={mainLogo}
                alt=''
                style={{
                  width: 90,
                  margin: '1rem',
                  marginBottom: 0,
                }}
              />
              <h2 style={{ marginBottom: 0 }}>StressTouch</h2>
              <div> Система управления содержимым</div>
            </Form.Item>
            <Form.Item
              name='email'
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: '',
                },
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder='Email'
                onChange={() => setValidateMess()}
              />
            </Form.Item>
            <Form.Item
              name='password'
              rules={[
                {
                  required: true,
                  min: 8,
                  message: '',
                },
              ]}
              style={{ marginBottom: validateMess ? 0 : '8px' }}
            >
              <Input
                prefix={<LockOutlined />}
                type='password'
                placeholder='Пароль'
                onChange={() => setValidateMess()}
              />
            </Form.Item>
            {validateMess && (
              <Typography.Text type='danger'>{validateMess}</Typography.Text>
            )}
            <Form.Item
              name='remember'
              valuePropName='checked'
              style={{ marginBottom: validateMess ? '14px' : '24px' }}
            >
              <Checkbox>Запомнить меня</Checkbox>
            </Form.Item>

            <Form.Item>
              <Button type='primary' htmlType='submit' block>
                Войти
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Spin>
    </div>
  );
}
