import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

export default function AppResult({ data, pathSection }) {
  const navigate = useNavigate();
  return (
    <Result
      status={data.status}
      title={data.title}
      subTitle={data.subtitle}
      extra={[
        <Button
          type='primary'
          onClick={() => {
            navigate(pathSection);
          }}
          key='section'
        >
          К разделу
        </Button>,
        data.additionalButtonText && (
          <Button onClick={data.additionalLogic} key='more'>
            {data.additionalButtonText}
          </Button>
        ),
      ]}
    />
  );
}
