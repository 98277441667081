import { Avatar, Button, List, Modal, Tag } from 'antd';
import { showDeleteConfirm } from '../common/DeleteModal';

export default function PracticeItemList({
  item,
  onDeleteClick,
  onUpdateClick,
}) {
  const [modal, contextHolder] = Modal.useModal();

  const totalTime = Number(item.totalTime);
  let totalTimeColor = null;
  if (totalTime < 60) {
    totalTimeColor = 'success';
  } else if (totalTime < 120) {
    totalTimeColor = 'warning';
  } else {
    totalTimeColor = 'error';
  }
  return (
    <List.Item
      actions={[
        <Button
          type='link'
          onClick={() => {
            onUpdateClick(item.id);
          }}
        >
          Редактировать
        </Button>,
        <Button
          type='link'
          danger
          onClick={() => {
            showDeleteConfirm(
              modal,
              onDeleteClick,
              item.id,
              `Практика «${item.title}»`
            );
          }}
        >
          Удалить
        </Button>,
      ]}
      key={item.id}
      style={{ textAlign: 'left' }}
    >
      {contextHolder}
      <List.Item.Meta
        avatar={
          <Avatar
            src={item.coverImageData}
            size={50}
            shape='square'
            style={{ width: '75px' }}
          />
        }
        title={item.title}
        description={item.annotation}
      />
      <Tag color={totalTimeColor}>{`${item.totalTime} сек`}</Tag>
    </List.Item>
  );
}
