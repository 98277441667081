import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Flex, Input, List, message } from 'antd';
import PracticeItemList from './PracticeItemList';
import { deletePractice, getPractices } from '../../services/practiceService';

export default function PracticesList() {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [localData, setLocalData] = useState([]);

  useEffect(() => {
    setLoading(true);
    getPractices().then((response) => {
      setData(response);
      setLocalData(response);
      setLoading(false);
    });
  }, []);

  const handleDeleteClick = (id) => {
    deletePractice(id).then((response) => {
      if (response.ok) {
        messageApi.success('Практика удалена!');
        setLocalData(localData.filter((practice) => practice.id !== id));
      } else {
        messageApi.error('Ошибка');
      }
    });
  };

  const handleChangeSearch = (e) => {
    const substr = e.target.value.toLowerCase();
    setLocalData(
      data.filter(
        (exercise) => exercise.title.toLowerCase().indexOf(substr) >= 0
      )
    );
  };

  return (
    <>
      <Flex gap={'1rem'} style={{ marginBottom: '1rem' }}>
        <Input.Search
          placeholder='Введите название практики'
          onChange={handleChangeSearch}
          size='large'
        />
        <Button
          size='large'
          type='primary'
          onClick={() => {
            navigate('/practice/create');
          }}
          icon={<PlusOutlined />}
        >
          Добавить практику
        </Button>
      </Flex>
      {contextHolder}
      <List
        bordered
        loading={loading}
        dataSource={localData}
        renderItem={(item) => (
          <>
            <PracticeItemList
              item={item}
              onDeleteClick={handleDeleteClick}
              onUpdateClick={(id) =>
                navigate('/practice/update', { state: { id } })
              }
            />
          </>
        )}
      />
    </>
  );
}
