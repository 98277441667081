import { addSeconds } from 'date-fns';
export const baseURL = 'https://stresstouch.online';

export const fetchWrapper = async (url, options) => {
  let expiresIn = localStorage.getItem('expiresIn');
  if (expiresIn && new Date(expiresIn) < new Date()) {
    let tokenRefresh = localStorage.getItem('tokenRefresh');
    let response = await refresh({ tokenRefresh });
    if (response.status !== 200) {
      localStorage.removeItem('tokenAccess');
      localStorage.removeItem('tokenRefresh');
      localStorage.removeItem('expiresIn');
      return response;
    }
    const data = await response.json();
    localStorage.setItem('tokenAccess', data.tokenAccess);
    localStorage.setItem('tokenRefresh', data.tokenRefresh);
    localStorage.setItem(
      'expiresIn',
      addSeconds(new Date(), data.expiresIn - 5)
    );
    return await fetchWrapper(url, options);
  } else {
    const tokenAccess = localStorage.getItem('tokenAccess');
    if (tokenAccess) {
      options.headers = {
        ...options?.headers,
        authorization: `Bearer ${tokenAccess}`,
      };
    }

    const response = await fetch(`${baseURL}${url}`, options);
    return response;
  }
};

export const refresh = async (body) => {
  const response = await fetch(`${baseURL}/api/refresh`, {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
  return response;
};
