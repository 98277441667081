import React, { useState } from 'react';
import { Form, Tabs } from 'antd';
import TestGroup from './TestGroup';
import { EditOutlined } from '@ant-design/icons';
import NameGroupModal from './NameGroupModal';

export default function TestContent({ form, groups = [] }) {
  const [open, setOpen] = useState(false);
  const showNameModal = () => {
    setOpen(true);
  };

  const initialItems =
    groups.length === 0
      ? [
          {
            label: 'Основная',
            key: 0,
            icon: <EditOutlined onClick={showNameModal} />,
          },
        ]
      : groups.map((g, i) => ({
          label: g.name,
          key: i,
          icon: <EditOutlined onClick={showNameModal} />,
        }));

  const [activeKey, setActiveKey] = useState(initialItems[0].key);
  const [items, setItems] = useState(initialItems);
  const hideNameModal = () => {
    setOpen(false);
  };
  const onFinishNameModal = (value) => {
    if (!value) return;
    const itemIndx = items.indexOf(
      items.filter((item) => item.key === activeKey)[0]
    );
    items[itemIndx].label = value;
    let data = form.getFieldValue('testQuestionGroups');
    if (!data[itemIndx]) data[itemIndx] = {};
    data[itemIndx].name = value;
    form.setFieldValue('testQuestionGroups', data);
    setItems(items);
    setOpen(false);
  };

  const onChange = (newActiveKey) => {
    setActiveKey(newActiveKey);
  };

  const addGroup = () => {
    showNameModal();
    const newActiveKey = items?.[items?.length - 1]?.key + 1 || 0;
    const newPanes = [...items];
    newPanes.push({
      label: 'Новая группа',
      key: newActiveKey,
      icon: <EditOutlined onClick={showNameModal} />,
    });
    setItems(newPanes);
    setActiveKey(newActiveKey);
  };

  const removeGroup = (targetKey) => {
    let newActiveKey = activeKey;
    let lastIndex = -1;
    items.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newPanes = items.filter((item) => item.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    setItems(newPanes);
    setActiveKey(newActiveKey);
  };

  return (
    <>
      <NameGroupModal
        open={open}
        onCancel={hideNameModal}
        onOk={onFinishNameModal}
      />
      <Form.List name={'testQuestionGroups'}>
        {(fields, { add, remove }) => (
          <>
            <Tabs
              type='editable-card'
              onChange={onChange}
              activeKey={activeKey}
              onEdit={(targetKey, action) => {
                if (action === 'add') {
                  add();
                  addGroup();
                } else {
                  const itemIndx = items.indexOf(
                    items.filter((item) => item.key === targetKey)[0]
                  );
                  removeGroup(targetKey);
                  remove(itemIndx);
                }
              }}
              items={items}
            />
            {fields.map(
              ({ key, name }) =>
                name ===
                  items.indexOf(
                    items.filter((item) => item.key === activeKey)[0]
                  ) && <TestGroup key={key} form={form} nameGroup={name} />
            )}
          </>
        )}
      </Form.List>
    </>
  );
}
