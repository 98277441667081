import { Flex, Form, Input, message, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';

export default function ArticleInfo({ coverImage = null }) {
  const [messageApi, contextHolder] = message.useMessage();
  const [fileList, setFileList] = useState(
    !coverImage
      ? []
      : [
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            thumbUrl: coverImage,
          },
        ]
  );

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const normFile = (e) => {
    if (e?.file?.size > 1000000) {
      messageApi.error('Слишком большой файл!');
      return null;
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleBeforeUppload = (file) => {
    if (file.size <= 1000000) {
      messageApi.success('Файл загружен!');
    }
    return false;
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {contextHolder}
      <Form.Item
        name={'coverImageData'}
        style={{ marginRight: '64px', textAlign: 'left' }}
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Загрузите файл' }]}
      >
        <Upload
          onChange={({ fileList: newFileList }) => {
            setFileList(newFileList);
          }}
          previewFile={getBase64}
          fileList={fileList}
          accept='.png'
          listType='picture-card'
          beforeUpload={handleBeforeUppload}
          showUploadList={{ showPreviewIcon: false }}
        >
          {fileList.length === 0 && (
            <button
              style={{
                border: 0,
                background: 'none',
              }}
              type='button'
            >
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Загрузить обложку</div>
            </button>
          )}
        </Upload>
      </Form.Item>
      <Flex wrap='wrap' style={{ width: '100%', marginRight: '3rem' }}>
        <Form.Item
          name={'title'}
          style={{ width: '100%' }}
          rules={[{ required: true, message: '' }]}
        >
          <Input placeholder='Название' />
        </Form.Item>
        <Form.Item
          name={'tag'}
          style={{ width: '100%' }}
          rules={[{ required: true, message: '' }]}
        >
          <Input placeholder='Тег' />
        </Form.Item>
      </Flex>
    </div>
  );
}
