import { Button, Form, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AppResult from '../common/AppResult';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPractice, putPractice } from '../../services/practiceService';
import PracticeInfo from './PracticeInfo';
import PracticeStep from './PracticeStep';
import dayjs from 'dayjs';
import { postImage } from '../../services/imageService';

export default function UpdatePractice() {
  const location = useLocation();
  const [form] = Form.useForm();
  const [responseResult, setResponseResult] = useState();
  const [stepsList, setStepsList] = useState([]);
  const [data, setData] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    getPractice(location.state.id).then((response) => {
      for (let step of response.practiceSteps) {
        step.time = dayjs(
          `${Math.floor(step.time / 60)}:${step.time % 60}`,
          'mm:ss'
        );
      }
      response.practiceSteps = response.practiceSteps.sort(
        (a, b) => a.position - b.position
      );
      setData(response);
      setStepsList(response.practiceSteps);
    });
  }, [location]);

  const onFinish = async (values) => {
    let requestData = structuredClone(values);
    const id = data.id;
    requestData.changeCoverImage = false;
    if (typeof requestData.coverImageData == 'object') {
      requestData.coverImageData = await postImage(
        'practice',
        requestData.coverImageData[0].originFileObj
      );
      requestData.changeCoverImage = true;
    }

    for (let i = 0; i < requestData.practiceSteps.length; ++i) {
      requestData.practiceSteps[i].position = i;
      const time = requestData.practiceSteps[i].time;
      requestData.practiceSteps[i].time = time.$m * 60 + time.$s;
      delete requestData.practiceSteps[i].id;
    }

    requestData.totalTime =
      requestData.practiceSteps[requestData.practiceSteps.length - 1].time;

    putPractice(id, requestData).then((response) => {
      if (response?.id) {
        setResponseResult({
          status: 'success',
          title: 'Отлично!',
          subtitle: `Практика «${values.title}» изменена.`,
        });
      } else {
        setResponseResult({
          status: 'error',
          title: 'Ошибка',
          subtitle: response,
          additionalButtonText: 'Назад',
          additionalLogic: () => setResponseResult(null),
        });
      }
    });
  };

  if (!data) return <Spin />;

  return (
    <>
      {!responseResult && (
        <>
          <h1 style={{ textAlign: 'left', marginTop: '0px' }}>
            Редактирование
          </h1>
          <Form form={form} initialValues={data} onFinish={onFinish}>
            <PracticeInfo coverImage={data.coverImageData} />

            <Form.List name={'practiceSteps'}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name }) => (
                    <PracticeStep
                      key={key}
                      name={name}
                      remove={(n) => {
                        remove(n);
                        setStepsList(form.getFieldValue([['practiceSteps']]));
                      }}
                    />
                  ))}
                  <Form.Item style={{ marginRight: '2rem' }}>
                    <Button
                      type='dashed'
                      onClick={() => {
                        add();
                        setStepsList(form.getFieldValue([['practiceSteps']]));
                      }}
                      block
                      icon={<PlusOutlined />}
                    >
                      Добавить шаг
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>

            <Form.Item>
              <Button
                onClick={() => {
                  form.resetFields();
                  navigate('/practice');
                }}
                style={{ marginRight: '2rem', width: '95px' }}
              >
                Назад
              </Button>
              <Button
                htmlType='submit'
                type='primary'
                disabled={stepsList.length === 0}
              >
                Изменить
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
      {responseResult && (
        <AppResult data={responseResult} pathSection={'/practice'} />
      )}
    </>
  );
}
