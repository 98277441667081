import { Button, Divider, Form, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ArticleContent from './ArticleContent';
import ArticleInfo from './ArticleInfo';
import { postArticle } from '../../services/articleService';
import AppResult from '../common/AppResult';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { postImage } from '../../services/imageService';

export default function CreateArticle() {
  const [form] = Form.useForm();
  const [responseResult, setResponseResult] = useState();
  const [contentList, setContentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const clearData = () => {
    form.resetFields();
    setResponseResult(null);
  };

  const onFinish = async (values) => {
    setLoading(true);
    values.coverImageData = await postImage(
      'article',
      values.coverImageData[0].originFileObj
    );

    for (let i = 0; i < values.articleContents.length; ++i) {
      values.articleContents[i].position = i;
      if (values.articleContents[i].type === 'image') {
        values.articleContents[i].item = await postImage(
          'article-content',
          values.articleContents[i].item[0].originFileObj
        );
      }
    }

    postArticle(values).then(async (response) => {
      setLoading(false);
      if (response?.id) {
        setResponseResult({
          status: 'success',
          title: 'Отлично!',
          subtitle: `Статья «${values.title}» добавлена в информационную базу.`,
          additionalButtonText: 'Добавить еще',
          additionalLogic: clearData,
        });
      } else {
        setResponseResult({
          status: 'error',
          title: 'Ошибка',
          subtitle: response,
          additionalButtonText: 'Назад',
          additionalLogic: () => setResponseResult(null),
        });
      }
    });
  };

  return (
    <>
      {!responseResult && (
        <Spin spinning={loading} delay={500}>
          <h1 style={{ textAlign: 'left', marginTop: '0px' }}>
            Создание статьи
          </h1>
          <Form form={form} onFinish={onFinish}>
            <ArticleInfo />

            {contentList.length > 0 && (
              <Divider style={{ marginTop: '0px' }}>Содержание</Divider>
            )}

            <Form.List name='articleContents'>
              {(fields, { add, remove, move }) => (
                <>
                  {fields.map(({ key, name }) => (
                    <ArticleContent
                      key={key}
                      name={name}
                      form={form}
                      remove={(n) => {
                        remove(n);
                        setContentList(
                          form.getFieldValue([['articleContents']])
                        );
                      }}
                      countContents={contentList.length}
                      move={move}
                    />
                  ))}
                  <Form.Item style={{ marginRight: '3rem' }}>
                    <Button
                      type='dashed'
                      onClick={() => {
                        add();
                        setContentList(
                          form.getFieldValue([['articleContents']])
                        );
                      }}
                      block
                      icon={<PlusOutlined />}
                    >
                      Добавить поле
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>

            <Form.Item style={{ marginRight: '3rem' }}>
              <Button
                onClick={() => {
                  form.resetFields();
                  navigate('/article');
                }}
                style={{ marginRight: '2rem', width: '85px' }}
              >
                Назад
              </Button>
              <Button
                htmlType='submit'
                type='primary'
                disabled={contentList.length === 0}
              >
                Создать
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      )}
      {responseResult && (
        <AppResult data={responseResult} pathSection={'/article'} />
      )}
    </>
  );
}
