import { Button, Form, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AppResult from '../common/AppResult';
import { useState } from 'react';
import PracticeInfo from './PracticeInfo';
import PracticeStep from './PracticeStep';
import { useNavigate } from 'react-router-dom';
import { postPractice } from '../../services/practiceService';
import { postImage } from '../../services/imageService';

export default function CreatePractices() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [responseResult, setResponseResult] = useState();
  const [stepsList, setStepsList] = useState([]);
  const navigate = useNavigate();

  const clearData = () => {
    form.resetFields();
    setResponseResult(null);
  };

  const onFinish = async (values) => {
    setLoading(true);
    values.coverImageData = await postImage(
      'practice',
      values.coverImageData[0].originFileObj
    );

    for (let i = 0; i < values.practiceSteps.length; ++i) {
      values.practiceSteps[i].position = i;
      const time = values.practiceSteps[i].time;
      values.practiceSteps[i].time = time.$m * 60 + time.$s;
    }
    values.totalTime =
      values.practiceSteps[values.practiceSteps.length - 1].time;

    postPractice(values).then((response) => {
      setLoading(false);
      if (response?.id) {
        setResponseResult({
          status: 'success',
          title: 'Отлично!',
          subtitle: `Практика «${values.title}» добавлено в информационную базу.`,
          additionalButtonText: 'Добавить еще',
          additionalLogic: clearData,
        });
      } else {
        setResponseResult({
          status: 'error',
          title: 'Ошибка',
          subtitle: response,
          additionalButtonText: 'Назад',
          additionalLogic: () => setResponseResult(null),
        });
      }
    });
  };

  return (
    <>
      {!responseResult && (
        <Spin spinning={loading} delay={500}>
          <h1 style={{ textAlign: 'left', marginTop: '0px' }}>
            Создание практики
          </h1>
          <Form form={form} onFinish={onFinish}>
            <PracticeInfo form={form} />

            <Form.List name='practiceSteps'>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name }) => (
                    <PracticeStep
                      key={key}
                      name={name}
                      remove={(n) => {
                        remove(n);
                        setStepsList(form.getFieldValue([['practiceSteps']]));
                      }}
                    />
                  ))}
                  <Form.Item style={{ marginRight: '2rem' }}>
                    <Button
                      type='dashed'
                      onClick={() => {
                        add();
                        setStepsList(form.getFieldValue([['practiceSteps']]));
                      }}
                      block
                      icon={<PlusOutlined />}
                    >
                      Добавить шаг
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>

            <Form.Item>
              <Button
                onClick={() => {
                  form.resetFields();
                  navigate('/practice');
                }}
                style={{ marginRight: '2rem', width: '85px' }}
              >
                Назад
              </Button>
              <Button
                htmlType='submit'
                type='primary'
                disabled={stepsList.length === 0}
              >
                Создать
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      )}
      {responseResult && (
        <AppResult data={responseResult} pathSection={'/practice'} />
      )}
    </>
  );
}
