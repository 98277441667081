import { fetchWrapper } from '../interceptors/fetchWrapper';

export const postTest = async (body) => {
  try {
    const response = await fetchWrapper('/api/test', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      return (await response.json()).message;
    }
    return await response.json();
  } catch {
    return 'Error';
  }
};

export const getTests = async () => {
  const response = await fetchWrapper('/api/tests', { headers: {} });
  if (!response.ok) {
    return [];
  }
  return await response.json();
};

export const getTest = async (id) => {
  const response = await fetchWrapper(`/api/test/${id}`, {
    headers: {},
  });
  if (!response.ok) {
    return {};
  }
  return await response.json();
};

export const deleteTest = async (id) => {
  const response = await fetchWrapper(`/api/test/${id}`, {
    method: 'DELETE',
    headers: {},
  });
  return response;
};

export const putTest = async (id, body) => {
  try {
    const response = await fetchWrapper(`/api/test/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      return (await response.json()).message;
    }
    return await response.json();
  } catch {
    return 'Error';
  }
};
