import { ExclamationCircleOutlined } from '@ant-design/icons';

export const showDeleteConfirm = (modal, remove, id, title) => {
  modal.confirm({
    title: 'Вы уверены, что хотите удалить?',
    icon: <ExclamationCircleOutlined />,
    content: title,
    okText: 'Да',
    okType: 'danger',
    cancelText: 'Нет',
    onOk() {
      remove(id);
    },
    onCancel() {},
  });
};
