import { Form, Input, Modal } from 'antd';

export default function NameGroupModal({ open, onOk, onCancel }) {
  const [form] = Form.useForm();
  return (
    <Modal
      title='Название группы'
      open={open}
      onOk={() => {
        onOk(form.getFieldValue('name'));
        form.resetFields();
      }}
      onCancel={onCancel}
      cancelText='Назад'
    >
      <Form form={form} layout='vertical' name='nameForm' onFinish={onOk}>
        <Form.Item
          name={'name'}
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
