import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Flex, Input, List, message } from 'antd';
import TestItemList from './TestItemList';
import { deleteTest, getTests } from '../../services/testService';

export default function TestsList() {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [localData, setLocalData] = useState([]);

  useEffect(() => {
    setLoading(true);
    getTests().then((response) => {
      setData(response);
      setLocalData(response);
      setLoading(false);
    });
  }, []);

  const handleDeleteClick = (id) => {
    deleteTest(id).then((response) => {
      if (response.ok) {
        messageApi.success('Тест удален!');
        setLocalData(localData.filter((test) => test.id !== id));
      } else {
        messageApi.error('Ошибка');
      }
    });
  };

  const handleChangeSearch = (e) => {
    const substr = e.target.value.toLowerCase();
    setLocalData(
      data.filter((test) => test.name.toLowerCase().indexOf(substr) >= 0)
    );
  };

  return (
    <>
      <Flex gap={'1rem'} style={{ marginBottom: '1rem' }}>
        <Input.Search
          placeholder='Введите название теста'
          onChange={handleChangeSearch}
          size='large'
        />
        <Button
          size='large'
          type='primary'
          onClick={() => {
            navigate('/test/create');
          }}
          icon={<PlusOutlined />}
        >
          Добавить тест
        </Button>
      </Flex>
      {contextHolder}
      <List
        bordered
        loading={loading}
        dataSource={localData}
        renderItem={(item) => (
          <>
            <TestItemList
              item={item}
              onDeleteClick={handleDeleteClick}
              onUpdateClick={(id) =>
                navigate('/test/update', { state: { id } })
              }
            />
          </>
        )}
      />
    </>
  );
}
