import { Layout } from 'antd';
import { Route, Routes } from 'react-router-dom';
import ArticlesList from '../articles/ArticlesList';
import CreateArticle from '../articles/CreateArticle';
import UpdateArticle from '../articles/UpdateArticle';
import ExercisesList from '../exercises/ExercisesList';
import CreateExercise from '../exercises/CreateExercise';
import UpdateExercise from '../exercises/UpdateExercise';
import CreatePractices from '../practices/CreatePractices';
import PracticesList from '../practices/PracticesList';
import UpdatePractice from '../practices/UpdatePractice';
import CreateTest from '../tests/CreateTest';
import TestsList from '../tests/TestsList';
import UpdateTest from '../tests/UpdateTest';

const contentStyle = {
  margin: '1rem',
  padding: 24,
  textAlign: 'center',
  minHeight: 'calc(100vh - 60px)',
  background: 'white',
  borderRadius: 8,
};

export default function AppContent() {
  return (
    <Layout.Content style={contentStyle}>
      <Routes>
        <Route path='/home' element={<h1>Добро пожаловать!</h1>} />
        <Route path='/article' element={<ArticlesList />} />
        <Route path='/article/create' element={<CreateArticle />} />
        <Route path='/article/update' element={<UpdateArticle />} />
        <Route path='/exercise' element={<ExercisesList />} />
        <Route path='/exercise/create' element={<CreateExercise />} />
        <Route path='/exercise/update' element={<UpdateExercise />} />
        <Route path='/practice' element={<PracticesList />} />
        <Route path='/practice/create' element={<CreatePractices />} />
        <Route path='/practice/update' element={<UpdatePractice />} />
        <Route path='/test' element={<TestsList />} />
        <Route path='/test/create' element={<CreateTest />} />
        <Route path='/test/update' element={<UpdateTest />} />
      </Routes>
    </Layout.Content>
  );
}
