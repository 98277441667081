import React, { useEffect, useState } from 'react';
import { Button, List, Input, Flex, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ArticleItemList from './ArticleItemList';
import { deleteArticle, getArticles } from '../../services/articleService';
import { useNavigate } from 'react-router-dom';

export default function ArticlesList() {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [localData, setLocalData] = useState([]);

  useEffect(() => {
    setLoading(true);
    getArticles().then((response) => {
      setData(response);
      setLocalData(response);
      setLoading(false);
    });
  }, []);

  const handleDeleteClick = (id) => {
    deleteArticle(id).then((response) => {
      if (response.ok) {
        messageApi.success('Статья удалена!');
        setLocalData(localData.filter((article) => article.id !== id));
      } else {
        messageApi.error('Ошибка');
      }
    });
  };

  const handleChangeSearch = (e) => {
    const substr = e.target.value.toLowerCase();
    setLocalData(
      data.filter((article) => article.title.toLowerCase().indexOf(substr) >= 0)
    );
  };

  const tagColors = [
    'magenta',
    'red',
    'volcano',
    'orange',
    'gold',
    'lime',
    'green',
    'cyan',
    'blue',
    'geekblue',
    'purple',
  ];

  return (
    <>
      <Flex gap={'1rem'} style={{ marginBottom: '1rem' }}>
        <Input.Search
          placeholder='Введите название статьи'
          onChange={handleChangeSearch}
          size='large'
        />
        <Button
          size='large'
          type='primary'
          onClick={() => {
            navigate('/article/create');
          }}
          icon={<PlusOutlined />}
        >
          Добавить статью
        </Button>
      </Flex>
      {contextHolder}
      <List
        bordered
        loading={loading}
        dataSource={localData}
        renderItem={(item, index) => (
          <>
            <ArticleItemList
              item={item}
              onDeleteClick={handleDeleteClick}
              onUpdateClick={(id) =>
                navigate('/article/update', { state: { id } })
              }
              tagColor={tagColors[index % tagColors.length]}
            />
          </>
        )}
      />
    </>
  );
}
