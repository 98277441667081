import { Avatar, Button, List, Modal, Tag } from 'antd';
import { showDeleteConfirm } from '../common/DeleteModal';

export default function TestItemList({ item, onDeleteClick, onUpdateClick }) {
  const [modal, contextHolder] = Modal.useModal();

  let tagColor;
  switch (item.testCategory.title) {
    case 'Актуальный стресс':
      tagColor = 'magenta';
      break;
    case 'Хронический стресс':
      tagColor = 'purple';
      break;
    case 'Профилактика стресса':
      tagColor = 'gold';
      break;
    default:
      tagColor = 'error';
  }

  return (
    <List.Item
      actions={[
        <Button
          type='link'
          onClick={() => {
            onUpdateClick(item.id);
          }}
        >
          Редактировать
        </Button>,
        <Button
          type='link'
          danger
          onClick={() => {
            showDeleteConfirm(
              modal,
              onDeleteClick,
              item.id,
              `Тест «${item.name}»`
            );
          }}
        >
          Удалить
        </Button>,
      ]}
      key={item.id}
      style={{ textAlign: 'left' }}
    >
      {contextHolder}
      <List.Item.Meta
        avatar={
          <Avatar
            src={item.coverImageData}
            size={50}
            style={{ width: '75px' }}
          />
        }
        title={item.name}
        description={item.description}
      />
      <Tag color={tagColor}>{item.testCategory.title}</Tag>
    </List.Item>
  );
}
