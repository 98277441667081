import { Button, Card, Divider, Flex, Form, Input, InputNumber } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import TestOption from './TestOption';

export default function TestQuestion({ nameQuestion, removeQuestion }) {
  return (
    <Flex gap={'1rem'}>
      <Card style={{ width: '100%', marginBottom: '1.5rem' }}>
        <Divider orientation='left' style={{ marginTop: '-1rem' }}>
          {'Вопрос ' + String(nameQuestion + 1)}
        </Divider>
        <Flex gap={'1rem'} style={{ marginRight: 0 }}>
          <Form.Item
            name={[nameQuestion, 'position']}
            rules={[{ required: true, message: '' }]}
          >
            <InputNumber placeholder='Номер' />
          </Form.Item>
          <Form.Item
            name={[nameQuestion, 'text']}
            rules={[{ required: true, message: '' }]}
            style={{ width: '100%' }}
          >
            <Input placeholder='Текст' />
          </Form.Item>
        </Flex>
        <Flex gap={'5%'} wrap='wrap'>
          <Form.List
            name={[nameQuestion, 'testOptions']}
            style={{ marginBottom: 0 }}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name }) => (
                  <TestOption
                    key={key}
                    nameOption={name}
                    removeOption={remove}
                  />
                ))}
                <Form.Item
                  style={{
                    marginBottom: 0,
                    width: '30%',
                  }}
                >
                  <div style={{ marginRight: '2rem' }}>
                    <Button
                      type='dashed'
                      onClick={() => {
                        add();
                      }}
                      block
                      icon={<PlusOutlined />}
                    >
                      Добавить вариант ответа
                    </Button>
                  </div>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Flex>
      </Card>
      <CloseOutlined
        onClick={() => removeQuestion(nameQuestion)}
        style={{ width: '1rem', marginBottom: '1.5rem' }}
      />
    </Flex>
  );
}
