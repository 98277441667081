import {
  Button,
  Divider,
  Flex,
  Form,
  Input,
  message,
  Select,
  Upload,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';

export default function TestInfo({
  coverImage = null,
  firstScreenImage = null,
}) {
  const [messageApi, contextHolder] = message.useMessage();
  const [coverFileList, setCoverFileList] = useState(
    !coverImage
      ? []
      : [
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            thumbUrl: coverImage,
          },
        ]
  );

  const [firstScreenFileList, setFirstScreenFileList] = useState(
    !firstScreenImage
      ? []
      : [
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            thumbUrl: firstScreenImage,
          },
        ]
  );

  const categories = [
    'Хронический стресс',
    'Актуальный стресс',
    'Профилактика стресса',
  ];

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const normFile = (e) => {
    if (e?.file?.size > 1000000) {
      messageApi.error('Слишком большой файл!');
      return null;
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleBeforeUpload = (file) => {
    if (file.size <= 1000000) {
      messageApi.success('Файл загружен!');
    }
    return false;
  };

  return (
    <div>
      {contextHolder}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginRight: '2rem',
        }}
      >
        <Flex
          style={{
            width: '20%',
            marginRight: '2rem',
            justifyContent: 'space-between',
          }}
        >
          <Divider
            type='vertical'
            style={{ height: '6.5rem', marginLeft: 0 }}
          />
          <Form.Item
            name={'coverImageData'}
            getValueFromEvent={normFile}
            rules={[{ required: true, message: 'Загрузите файл' }]}
          >
            <Upload
              onChange={({ fileList: newFileList }) => {
                setCoverFileList(newFileList);
              }}
              previewFile={getBase64}
              fileList={coverFileList}
              accept='.png'
              listType='picture-card'
              beforeUpload={handleBeforeUpload}
              showUploadList={{ showPreviewIcon: false }}
            >
              {coverFileList.length === 0 && (
                <button
                  style={{
                    border: 0,
                    background: 'none',
                    margin: 0,
                  }}
                  type='button'
                >
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Загрузить обложку</div>
                </button>
              )}
            </Upload>
          </Form.Item>
          <Divider
            type='vertical'
            style={{ height: '6.5rem', marginRight: 0 }}
          />
        </Flex>
        <Flex wrap='wrap' style={{ width: '100%' }}>
          <Form.Item
            name={'name'}
            style={{ width: '100%' }}
            rules={[{ required: true, message: '' }]}
          >
            <Input placeholder='Название' />
          </Form.Item>
          <Form.Item
            name={'description'}
            style={{ width: '100%' }}
            rules={[{ required: true, message: '' }]}
          >
            <Input placeholder='Описание' />
          </Form.Item>
        </Flex>
      </div>
      <Flex gap={'2rem'}>
        <div style={{ width: '20%' }}>
          {firstScreenFileList.length !== 0 && (
            <Divider style={{ marginTop: 0, marginBottom: '32px' }} />
          )}
          <Form.Item
            name={'testCategoryTitle'}
            style={{ width: '100%' }}
            rules={[{ required: true, message: '' }]}
          >
            <Select
              options={categories.map((category) => ({
                label: category,
                value: category,
              }))}
              placeholder={'Категория'}
              style={{ textAlign: 'left', marginTop: '0px' }}
            />
          </Form.Item>
        </div>
        <Form.Item
          name={'firstScreenImageData'}
          style={{
            width: '100%',
            marginRight: '2rem',
            textAlign: 'left',
            marginTop: firstScreenFileList.length === 0 ? 0 : '-0.5rem',
          }}
          getValueFromEvent={normFile}
          rules={[{ required: true, message: 'Загрузите файл' }]}
        >
          <Upload
            name='image'
            listType='picture'
            accept='.png'
            previewFile={getBase64}
            beforeUpload={handleBeforeUpload}
            fileList={firstScreenFileList}
            onChange={({ fileList: newFileList }) => {
              setFirstScreenFileList(newFileList);
            }}
          >
            {firstScreenFileList.length === 0 && (
              <Button icon={<UploadOutlined />}>Загрузить файл</Button>
            )}
          </Upload>
        </Form.Item>
      </Flex>
    </div>
  );
}
