import { Button, Form, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AppResult from '../common/AppResult';
import ExerciseInfo from './ExerciseInfo';
import ExerciseStep from './ExerciseStep';
import { getExercise, putExercise } from '../../services/exerciseService';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { postImage } from '../../services/imageService';

export default function UpdateExercise() {
  const location = useLocation();
  const [form] = Form.useForm();
  const [responseResult, setResponseResult] = useState();
  const [stepsList, setStepsList] = useState([]);
  const [data, setData] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    getExercise(location.state.id).then((response) => {
      response.exerciseSteps = response.exerciseSteps.sort(
        (a, b) => a.position - b.position
      );
      setData(response);
      setStepsList(response.exerciseSteps);
    });
  }, [location]);

  const onFinish = async (values) => {
    const id = data.id;
    values.newImages = [];
    if (typeof values.coverImageData == 'object') {
      values.coverImageData = await postImage(
        'exercise',
        values.coverImageData[0].originFileObj
      );
      values.newImages.push(values.coverImageData);
    }

    for (let i = 0; i < values.exerciseSteps.length; ++i) {
      values.exerciseSteps[i].position = i;
      if (typeof values.exerciseSteps[i].imageData == 'object') {
        values.exerciseSteps[i].imageData = await postImage(
          'exercise-step',
          values.exerciseSteps[i].imageData[0].originFileObj
        );
        values.newImages.push(values.exerciseSteps[i].imageData);
      }
      delete values.exerciseSteps[i].id;
    }

    putExercise(id, values).then((response) => {
      if (response?.id) {
        setResponseResult({
          status: 'success',
          title: 'Отлично!',
          subtitle: `Упражнение «${values.title}» изменено.`,
        });
      } else {
        setResponseResult({
          status: 'error',
          title: 'Ошибка',
          subtitle: response,
          additionalButtonText: 'Назад',
          additionalLogic: () => setResponseResult(null),
        });
      }
    });
  };

  if (!data) return <Spin />;

  return (
    <>
      {!responseResult && (
        <>
          <h1 style={{ textAlign: 'left', marginTop: '0px' }}>
            Редактирование
          </h1>
          <Form form={form} initialValues={data} onFinish={onFinish}>
            <ExerciseInfo form={form} coverImage={data.coverImageData} />

            <Form.List name='exerciseSteps'>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name }) => (
                    <ExerciseStep
                      key={key}
                      name={name}
                      form={form}
                      remove={(n) => {
                        remove(n);
                        setStepsList(form.getFieldValue([['exerciseSteps']]));
                      }}
                      lastInd={stepsList.length - 1}
                      image={
                        name < data.exerciseSteps.length
                          ? data.exerciseSteps[name].imageData
                          : null
                      }
                    />
                  ))}
                  <Form.Item style={{ marginRight: '2rem' }}>
                    <Button
                      type='dashed'
                      onClick={() => {
                        add();
                        setStepsList(form.getFieldValue([['exerciseSteps']]));
                      }}
                      block
                      icon={<PlusOutlined />}
                    >
                      Добавить поле
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>

            <Form.Item>
              <Button
                onClick={() => {
                  form.resetFields();
                  navigate('/exercise');
                }}
                style={{ marginRight: '2rem', width: '95px' }}
              >
                Назад
              </Button>
              <Button
                htmlType='submit'
                type='primary'
                disabled={stepsList.length === 0}
              >
                Изменить
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
      {responseResult && (
        <AppResult data={responseResult} pathSection={'/exercise'} />
      )}
    </>
  );
}
