import { Form, Input, Select, Button, Upload, Flex, message } from 'antd';
import { useState } from 'react';
import {
  CloseOutlined,
  UploadOutlined,
  UpOutlined,
  DownOutlined,
} from '@ant-design/icons';

const typeContentOptions = [
  { value: 'subtitle', label: 'Подзаголовок' },
  { value: 'text', label: 'Текст' },
  { value: 'image', label: 'Изображение' },
];

export default function ArticleContent({
  name,
  form,
  remove,
  move,
  countContents,
  image = null,
}) {
  const [messageApi, contextHolder] = message.useMessage();
  const contentItem = form.getFieldValue([['articleContents']])[name];
  const [typeContent, setTypeContent] = useState(
    contentItem ? contentItem.type : null
  );
  const [fileList, setFileList] = useState(
    image
      ? [
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            thumbUrl: image,
          },
        ]
      : []
  );

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const normFile = (e) => {
    if (e?.file?.size > 1000000) {
      messageApi.error('Слишком большой файл!');
      return null;
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleBeforeUpdate = (file) => {
    if (file.size <= 1000000) {
      messageApi.success('Файл загружен!');
    }
    return false;
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      {contextHolder}
      <Form.Item
        name={[name, 'type']}
        style={{ width: '180px', marginRight: '1rem' }}
        rules={[{ required: true, message: '' }]}
      >
        <Select
          options={typeContentOptions.map((type) => ({
            label: type.label,
            value: type.value,
          }))}
          style={{ textAlign: 'left' }}
          onChange={(val) => {
            setTypeContent(val);
          }}
        />
      </Form.Item>
      <Flex
        style={{
          width: '100%',
        }}
      >
        {!typeContent && (
          <Form.Item
            name={[name, 'defaultField']}
            style={{
              width: '100%',
              textAlign: 'left',
              marginRight: '0.5rem',
            }}
            rules={[{ required: true, message: '' }]}
          >
            <Input disabled />
          </Form.Item>
        )}
        {typeContent === 'subtitle' && (
          <Form.Item
            name={[name, typeContent === 'subtitle' ? 'item' : 'subtitle']}
            rules={[{ required: true, message: '' }]}
            style={{
              width: '100%',
              marginRight: '0.5rem',
              textAlign: 'left',
            }}
          >
            <Input style={{ fontWeight: 500, opacity: 0.95 }} />
          </Form.Item>
        )}
        {typeContent === 'text' && (
          <Form.Item
            name={[name, typeContent === 'text' ? 'item' : 'text']}
            rules={[{ required: true, message: '' }]}
            style={{
              width: '100%',
              marginRight: '0.5rem',
              textAlign: 'left',
            }}
          >
            <Input.TextArea autoSize />
          </Form.Item>
        )}
        {typeContent === 'image' && (
          <Form.Item
            name={[name, typeContent === 'image' ? 'item' : 'image']}
            style={{
              width: '100%',
              marginRight: '0.5rem',
              textAlign: 'left',
              marginTop: fileList.length === 0 ? '0px' : '-8px',
            }}
            rules={[{ required: true, message: 'Загрузите файл' }]}
            getValueFromEvent={normFile}
          >
            <Upload
              listType='picture'
              accept='.png'
              previewFile={getBase64}
              beforeUpload={handleBeforeUpdate}
              fileList={fileList}
              onChange={({ fileList: newFileList }) => {
                setFileList(newFileList);
              }}
            >
              {fileList.length === 0 && (
                <Button icon={<UploadOutlined />}>Загрузить файл</Button>
              )}
            </Upload>
          </Form.Item>
        )}
        <Flex gap={'0.5rem'} style={{ marginBottom: '1.5rem' }}>
          <Flex wrap='wrap' style={{ width: '1rem' }}>
            <UpOutlined
              style={{
                color: name === 0 ? 'ButtonShadow' : '#bfbfbf',
                width: '0.7rem',
              }}
              onClick={() => move(name, name - 1)}
              disabled={name === 0}
            />
            <DownOutlined
              style={{
                color: name === countContents - 1 ? 'ButtonShadow' : '#bfbfbf',
                width: '0.7rem',
                marginTop: 0,
              }}
              onClick={() => move(name, name + 1)}
            />
          </Flex>

          <CloseOutlined
            onClick={() => remove(name)}
            style={{ width: '1rem', color: '#F7698C' }}
          />
        </Flex>
      </Flex>
    </div>
  );
}
