import { Card, Flex, Upload, Form, Divider, message } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import TextArea from 'antd/es/input/TextArea';

export default function ExerciseStep({
  name,
  form,
  remove,
  lastInd,
  image = null,
}) {
  const [messageApi, contextHolder] = message.useMessage();
  const [fileList, setFileList] = useState(
    !image
      ? []
      : [
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            thumbUrl: image,
          },
        ]
  );

  let nameStep = null;
  if (name === 0) nameStep = 'Подготовка';
  else if (name === lastInd) nameStep = 'Польза';
  else nameStep = String(name) + ' Шаг';

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const normFile = (e) => {
    if (e?.file?.size > 1000000) {
      messageApi.error('Слишком большой файл!');
      return null;
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleBeforeUppload = (file) => {
    if (file.size <= 1000000) {
      messageApi.success('Файл загружен!');
    }
    return false;
  };

  return (
    <Flex style={{ justifyContent: 'space-between' }}>
      <Card
        style={{
          width: '100%',
          marginRight: '1rem',
          marginBottom: '1rem',
        }}
      >
        {contextHolder}
        <Flex gap='1rem'>
          <Form.Item
            name={[name, 'imageData']}
            style={{ marginBottom: -6 }}
            getValueFromEvent={normFile}
            rules={[{ required: true, message: 'Загрузите файл' }]}
          >
            <Upload
              onChange={({ fileList: newFileList }) => {
                setFileList(newFileList);
              }}
              fileList={fileList}
              accept='.png'
              previewFile={getBase64}
              listType='picture-card'
              beforeUpload={handleBeforeUppload}
              showUploadList={{ showPreviewIcon: false }}
            >
              {fileList.length === 0 && (
                <button
                  style={{
                    border: 0,
                    background: 'none',
                  }}
                  type='button'
                >
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Загрузить</div>
                </button>
              )}
            </Upload>
          </Form.Item>
          <Flex wrap='wrap' style={{ width: '100%' }}>
            <Divider orientation='left' style={{ marginTop: 0 }}>
              {nameStep}
            </Divider>
            <Form.Item
              name={[name, 'description']}
              rules={[{ required: true, message: '' }]}
              style={{
                width: '100%',
                textAlign: 'left',
                margin: 0,
              }}
            >
              <TextArea placeholder='Описание шага' />
            </Form.Item>
          </Flex>
        </Flex>
      </Card>
      <CloseOutlined onClick={() => remove(name)} style={{ width: '1rem' }} />
    </Flex>
  );
}
