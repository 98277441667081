import { Layout } from 'antd';
import AppHeader from '../components/layout/AppHeader';
import AppSider from '../components/layout/AppSider';
import AppContent from '../components/layout/AppContent';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';

export default function Home() {
  const [collapsed, setCollapsed] = useState(false);
  if (!localStorage.getItem('tokenRefresh'))
    return <Navigate to='/login' replace />;
  return (
    <Layout>
      <AppSider collapsed={collapsed} />
      <Layout style={{ marginLeft: collapsed ? '80px' : '15%' }}>
        <AppHeader
          collapsed={collapsed}
          changeCollapsed={() => setCollapsed(!collapsed)}
        />
        <AppContent />
      </Layout>
    </Layout>
  );
}
