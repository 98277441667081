import { Avatar, Button, List, Modal, Tag } from 'antd';
import { showDeleteConfirm } from '../common/DeleteModal';

export default function ExerciseItemList({
  item,
  onDeleteClick,
  onUpdateClick,
}) {
  const [modal, contextHolder] = Modal.useModal();

  const difficulty = Number(item.difficulty[0]);
  let difficultyColor = null;
  if (difficulty < 4) {
    difficultyColor = 'success';
  } else if (difficulty < 7) {
    difficultyColor = 'warning';
  } else {
    difficultyColor = 'error';
  }
  return (
    <List.Item
      actions={[
        <Button
          type='link'
          onClick={() => {
            onUpdateClick(item.id);
          }}
        >
          Редактировать
        </Button>,
        <Button
          type='link'
          danger
          onClick={() => {
            showDeleteConfirm(
              modal,
              onDeleteClick,
              item.id,
              `Упражнение «${item.title}»`
            );
          }}
        >
          Удалить
        </Button>,
      ]}
      key={item.id}
      style={{ textAlign: 'left' }}
    >
      {contextHolder}
      <List.Item.Meta
        avatar={<Avatar src={item.coverImageData} size={50} shape='square' />}
        title={item.title}
        description={item.annotation}
      />
      <Tag color={difficultyColor}>{item.difficulty}</Tag>
    </List.Item>
  );
}
