import { Button, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AppResult from '../common/AppResult';
import { useState } from 'react';
import ExerciseInfo from './ExerciseInfo';
import ExerciseStep from './ExerciseStep';
import { postExercise } from '../../services/exerciseService';
import { useNavigate } from 'react-router-dom';
import { postImage } from '../../services/imageService';

export default function CreateExercise() {
  const [form] = Form.useForm();
  const [responseResult, setResponseResult] = useState();
  const [stepsList, setStepsList] = useState([]);
  const navigate = useNavigate();

  const clearData = () => {
    form.resetFields();
    setResponseResult(null);
  };

  const onFinish = async (values) => {
    values.coverImageData = await postImage(
      'exercise',
      values.coverImageData[0].originFileObj
    );
    for (let i = 0; i < values.exerciseSteps.length; ++i) {
      values.exerciseSteps[i].position = i;
      values.exerciseSteps[i].imageData = await postImage(
        'exercise-step',
        values.exerciseSteps[i].imageData[0].originFileObj
      );
    }

    postExercise(values).then((response) => {
      if (response?.id) {
        setResponseResult({
          status: 'success',
          title: 'Отлично!',
          subtitle: `Упражнение «${values.title}» добавлено в информационную базу.`,
          additionalButtonText: 'Добавить еще',
          additionalLogic: clearData,
        });
      } else {
        setResponseResult({
          status: 'error',
          title: 'Ошибка',
          subtitle: response,
          additionalButtonText: 'Назад',
          additionalLogic: () => setResponseResult(null),
        });
      }
    });
  };

  return (
    <>
      {!responseResult && (
        <>
          <h1 style={{ textAlign: 'left', marginTop: '0px' }}>
            Создание упражнения
          </h1>
          <Form form={form} onFinish={onFinish}>
            <ExerciseInfo form={form} />

            <Form.List name='exerciseSteps'>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name }) => (
                    <ExerciseStep
                      key={key}
                      name={name}
                      form={form}
                      remove={(n) => {
                        remove(n);
                        setStepsList(form.getFieldValue([['exerciseSteps']]));
                      }}
                      lastInd={stepsList.length - 1}
                    />
                  ))}
                  <Form.Item style={{ marginRight: '2rem' }}>
                    <Button
                      type='dashed'
                      onClick={() => {
                        add();
                        setStepsList(form.getFieldValue([['exerciseSteps']]));
                      }}
                      block
                      icon={<PlusOutlined />}
                    >
                      Добавить поле
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>

            <Form.Item>
              <Button
                onClick={() => {
                  form.resetFields();
                  navigate('/exercise');
                }}
                style={{ marginRight: '2rem', width: '85px' }}
              >
                Назад
              </Button>
              <Button
                htmlType='submit'
                type='primary'
                disabled={stepsList.length === 0}
              >
                Создать
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
      {responseResult && (
        <AppResult data={responseResult} pathSection={'/exercise'} />
      )}
    </>
  );
}
