import { fetchWrapper } from '../interceptors/fetchWrapper';

export const postImage = async (bucket, body) => {
  try {
    const formData = new FormData();
    formData.append('file', body);
    const response = await fetchWrapper(`/api/image/${bucket}`, {
      method: 'POST',
      body: formData,
    });
    return (await response.json())?.name;
  } catch {
    return 'Error';
  }
};
