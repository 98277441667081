import { Avatar, Button, List, Modal, Tag } from 'antd';
import { showDeleteConfirm } from '../common/DeleteModal';

export default function ArticleItemList({
  item,
  onDeleteClick,
  onUpdateClick,
  tagColor,
}) {
  const [modal, contextHolder] = Modal.useModal();
  return (
    <List.Item
      actions={[
        <Button
          type='link'
          onClick={() => {
            onUpdateClick(item.id);
          }}
        >
          Редактировать
        </Button>,
        <Button
          type='link'
          danger
          onClick={() => {
            showDeleteConfirm(
              modal,
              onDeleteClick,
              item.id,
              `Статья «${item.title}»`
            );
          }}
        >
          Удалить
        </Button>,
      ]}
      key={item.id}
      style={{ textAlign: 'left' }}
    >
      {contextHolder}
      <List.Item.Meta
        avatar={
          <Avatar
            src={item.coverImageData}
            size={50}
            style={{ marginTop: '0.5rem' }}
          />
        }
        title={item.title}
        description={item.preview}
      />
      <Tag color={tagColor} style={{ marginLeft: '1rem' }}>
        {item.tag}
      </Tag>
    </List.Item>
  );
}
