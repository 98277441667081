import { Card, Divider, Flex, Form, Input, InputNumber, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
const colors = [
  { label: 'Зеленый', value: 'green' },
  { label: 'Оранжевый', value: 'orange' },
  { label: 'Красный', value: 'red' },
];

export default function TestInterpretation({
  nameInterpretation,
  removeInterpretation,
}) {
  return (
    <Flex gap={'1rem'}>
      <Card style={{ width: '100%', marginBottom: '1.5rem' }}>
        <Divider orientation='left' style={{ marginTop: '-1rem' }}>
          {'Уровень ' + String(nameInterpretation + 1)}
        </Divider>
        <Flex gap={'1.5rem'} style={{ justifyContent: 'space-between' }}>
          <Form.Item
            name={[nameInterpretation, 'color']}
            style={{ width: '100%' }}
            rules={[{ required: true, message: '' }]}
          >
            <Select
              options={colors.map((color) => ({
                label: color.label,
                value: color.value,
              }))}
              placeholder={'Цвет'}
              style={{ textAlign: 'left' }}
            />
          </Form.Item>
          <Form.Item
            name={[nameInterpretation, 'lowerLimit']}
            rules={[{ required: true, message: '' }]}
            style={{ width: '100%', marginLeft: 0 }}
          >
            <InputNumber
              placeholder='Нижняя граница'
              style={{
                width: '100%',
              }}
            />
          </Form.Item>
          <Form.Item
            name={[nameInterpretation, 'upperLimit']}
            rules={[{ required: true, message: '' }]}
            style={{ width: '100%', marginLeft: 0 }}
          >
            <InputNumber
              placeholder='Верхняя граница'
              style={{
                width: '100%',
              }}
            />
          </Form.Item>
        </Flex>
        <Form.Item
          name={[nameInterpretation, 'information']}
          rules={[{ required: true, message: '' }]}
          style={{ width: '100%' }}
        >
          <Input.TextArea
            autoSize
            placeholder='Информация'
            style={{
              width: '100%',
            }}
          />
        </Form.Item>
        <Form.Item
          name={[nameInterpretation, 'recommendation']}
          rules={[{ required: true, message: '' }]}
          style={{ width: '100%', marginBottom: 0 }}
        >
          <Input.TextArea
            autoSize
            placeholder='Рекомендация'
            style={{
              width: '100%',
            }}
          />
        </Form.Item>
      </Card>
      <CloseOutlined
        onClick={() => removeInterpretation(nameInterpretation)}
        style={{ width: '1rem', marginBottom: '1.5rem' }}
      />
    </Flex>
  );
}
