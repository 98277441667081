import { fetchWrapper } from '../interceptors/fetchWrapper';

export const login = async (body) => {
  const response = await fetchWrapper('/api/login', {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });

  return await response.json();
};

export const logout = async (body) => {
  const response = await fetchWrapper('/api/logout', {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });

  return response;
};

export const getUser = async () => {
  const response = await fetchWrapper('/api/user', { headers: {} });
  if (!response.ok) {
    return null;
  }
  return await response.json();
};
