import { Button, Checkbox, Flex, Form, Input, InputNumber, Radio } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import TestQuestion from './TestQuestion';
import { useState } from 'react';
import TestInterpretation from './TestInterpretation';

export default function TestGroup({ form, nameGroup }) {
  const [page, setPage] = useState('questions');
  const onPageChange = (event) => {
    setPage(event.target.value);
  };

  return (
    <>
      <Flex gap={'2rem'}>
        <Radio.Group value={page} onChange={onPageChange}>
          <Radio.Button value='questions'>Вопросы</Radio.Button>
          <Radio.Button value='interpretations'>Интерпретация</Radio.Button>
        </Radio.Group>
        <Form.Item
          name={[nameGroup, 'baseValue']}
          rules={[{ required: true, message: '' }]}
        >
          <InputNumber
            style={{ width: '100%' }}
            placeholder='Базовое значение'
          />
        </Form.Item>
        <Form.Item name={[nameGroup, 'main']} valuePropName='checked'>
          <Checkbox>Главная группа</Checkbox>
        </Form.Item>
        <Form.Item name={[nameGroup, 'name']} hidden>
          <Input type='hidden' />
        </Form.Item>
      </Flex>
      {page === 'questions' && (
        <Form.List name={[nameGroup, 'testQuestions']}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name }) => (
                <TestQuestion
                  key={key}
                  nameQuestion={name}
                  removeQuestion={remove}
                />
              ))}
              <Form.Item style={{ marginRight: '2rem' }}>
                <Flex gap={'1rem'}>
                  <Button
                    type='dashed'
                    onClick={() => {
                      const questions = form.getFieldValue([
                        ['testQuestionGroups'],
                      ])[nameGroup]?.testQuestions;

                      add({
                        testOptions:
                          questions[questions.length - 1]?.testOptions,
                      });
                    }}
                    block
                    icon={<PlusOutlined />}
                    disabled={
                      !form.getFieldValue([['testQuestionGroups']])[nameGroup]
                        ?.testQuestions
                    }
                  >
                    Дублировать варианты ответов
                  </Button>
                  <Button
                    type='dashed'
                    onClick={() => {
                      add();
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    Добавить вопрос
                  </Button>
                </Flex>
              </Form.Item>
            </>
          )}
        </Form.List>
      )}
      {page === 'interpretations' && (
        <Form.List name={[nameGroup, 'testInterpretations']}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name }) => (
                <TestInterpretation
                  key={key}
                  nameInterpretation={name}
                  removeInterpretation={remove}
                />
              ))}
              <Form.Item style={{ marginRight: '2rem' }}>
                <Button
                  type='dashed'
                  onClick={() => {
                    add();
                  }}
                  block
                  icon={<PlusOutlined />}
                >
                  Добавить интерпретацию
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )}
    </>
  );
}
